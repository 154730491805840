<template>
  <el-main class="rh-form">
    <form-hcacttitrecreate :routes="hctRoutes" />
  </el-main>
</template>
<script>
import FormHcacttitrecreate from "@/components/rh/formulaires/FormHcacttitrecreate";

export default {
  components: { "form-hcacttitrecreate": FormHcacttitrecreate },
  computed: {
    hctRoutes() {
      return {
        toedit: {
          name: "hcactivitetitreedit",
          params: { idhctitre: this.$store.state.hc.hcactiviteLastCreatedId },
        },
        tolist: { name: "hcactivitetitres" },
      };
    },
  },
};
</script>
